<template>
  <div class="flex justify-center">
    <div
      class="py-3 flex justify-between text-gray-500 custom-bg border-0 border-b border-gray-300 border-solid"
      style="z-index: 100; width: 1200px"
    >
      <div class="flex items-center">
        <span>馅好评</span>
      </div>
      <div class="flex items-center text-sm">
        <button class="mr-5" @click="goto('homepage')">首页</button
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavBar',
  props: {},
  methods: {
    goto(idName) {
      this.$emit('scrollChange', idName)
    },
  },
}
</script>

<style>
.custom-bg {
  background-color: linear-gradient(to right, #2b0657, #f5f5f5);
}
</style>
